import React from 'react';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  select: {
    backgroundColor: 'white',
    borderRadius: '50px',
    '&:focus': {
      borderRadius: '50px', // Ensure focus state has the same border radius
    },
  },
}));

function EmploymentTypeSelect({ debouncedUpdate, initialWorkStyle }) {
  const classes = useStyles();
  const options = ["Any","Remote", "In person", "Hybrid"];
  const [workStyle, setWorkStyle] = React.useState(initialWorkStyle);

  const handleChange = (e) => {
    setWorkStyle(e.target.value);
    debouncedUpdate("work_style", e.target.value);
  };

  return (
    <FormControl variant="outlined" fullWidth style={{ marginTop: '10px' }}>
      <Select
        value={workStyle}
        onChange={handleChange}
        className={classes.select}
        MenuProps={{
          PaperProps: {
            style: {
              borderRadius: '10px',
            },
          },
        }}
        classes={{
          root: classes.select,
          outlined: classes.select,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option.replace(" ", "-")} className={classes.menuItem}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default EmploymentTypeSelect;
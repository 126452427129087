import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { Button, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import { AiOutlineEdit } from "react-icons/ai";
import axios from "axios";
import Cookies from "js-cookie";
import LoginPage from "../Login";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";
import { server } from "../../server";
import { useLoaderData } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Markdown from "react-markdown";
import CompactProfile from "../../components/Social/CompactProfile";
import InvoiceStatusChangerModal from "../../components/Invoices/InvoiceStatusChangerModal";
import InvoiceShareModal from "../../components/Invoices/InvoiceShareModal";
import { toast } from "react-toastify";

const ManageInvoices = () => {
    const { user, isAuthenticated, loading } = useSelector((state) => state.user);
    const invoice = useLoaderData().invoice;
    const navigate = useNavigate();
    const location = useLocation();

    const goBackToManageInvoices = () => {

        // Check if the previous path matches the desired path
        if (location.state?.from == "ManageInvoices") {
            // If it does, go back
            navigate(-1);
        } else {
            // If it doesn't, navigate to the desired path
            navigate(`/manage-invoices/`);
        }
    };
    function launchCheckoutFlow() {
        // Retrieve the token from the cookies
        const token = Cookies.get('token');

        // Set up the authorization header with the token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        // retrieve the checkout session link
        axios
            .get(`${server}/invoices/checkout-session/${invoice?._id}`, config)
            .then((res) => {
                if (res.data.sessionUrl) {
                    window.location.href = res.data.sessionUrl;
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message);

            });
    }

    if (!isAuthenticated && !loading) {
        return <LoginPage />;
    }

    return (
        <div>
            <div className={`800px:w-[90%] mx-auto 800px:flex py-10 `}>
                <ProfileSideBar active={`manage-invoices`} />
                <div className="800px:pt-0 pt-4 flex flex-col w-full space-y-4 800px:mx-4 ">
                    <div className="w-full pt-4 shadow rounded-lg 800px:px-10 p-2">
                        <div className="flex justify-between mt-2 mb-5 text-black dark:text-[#FFFB00] ">
                            <button onClick={goBackToManageInvoices} className="flex items-center"><FaArrowLeft className="pr-1 text-black dark:text-[#FFFB00]" /> Back to invoices</button>
                        </div>
                        <ul className="text-sm font-[300] pb-2 dark:text-[#d9d9d9]">
                            <li>Invoice ID: {invoice.humanReadableId}</li>
                            <li>Invoice Date: {invoice.invoiceDate ? new Date(invoice.invoiceDate).toISOString().slice(0, 10) : "N/A Invoice has not yet been finalized"}</li>
                        </ul>
                        <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] dark:text-[#d9d9d9] pb-2">
                            {invoice.title}
                        </h1>

                        <Markdown className="prose text-black dark:text-white dark:prose-invert mx-auto">
                            {invoice.overview}</Markdown>
                    </div>
                    <div className="w-full py-4 shadow rounded-lg 800px:px-10 p-2">
                        <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] dark:text-[#d9d9d9] pb-2">
                            Contact info
                        </h1>
                        <div className="text-sm font-[300] dark:text-[#d9d9d9]">
                            <ul>
                                <li>Name: {invoice.contactInfo.name}</li>
                                <li>Address: {invoice.contactInfo.address}</li>
                            </ul>
                            <h1 className="block text-[15px] font-[600] text-[#000000ba] dark:text-[#d9d9d9] py-2 text-center 800px:text-left">
                                Invoice created by
                            </h1>
                            <CompactProfile userId={invoice.createdBy} showFollowButton={false} />
                        </div>
                    </div>
                    <div className="w-full py-4 shadow rounded-lg 800px:px-10 p-2">
                        <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] dark:text-[#d9d9d9] pb-2">
                            Deliverables
                        </h1>
                        <div className="">
                            {invoice.deliverables.map((deliverable, index) => (
                                <div key={index} className="py-2">
                                    <h1 className="block text-[15px] font-[600] text-[#000000ba] dark:text-[#d9d9d9] py-2">
                                        {deliverable.title}
                                    </h1>
                                    <Markdown className="prose text-black dark:text-white dark:prose-invert">
                                        {deliverable.overview}
                                    </Markdown>
                                    <p className="text-sm font-[300] dark:text-[#d9d9d9]">
                                        Cost: ${deliverable.costInCAD.toFixed(2)}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div className="text-lg font-[500] text-center dark:text-[#d9d9d9]">
                            Invoice Total: ${invoice.totalCostInCAD.toFixed(2)}
                        </div>
                    </div>
                    <div className="w-full py-4 shadow rounded-lg 800px:px-10 p-2">
                        <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] dark:text-[#d9d9d9] pb-2">
                            Status
                        </h1>
                        {invoice.status == "Draft" &&
                            <>
                                <p className="text-lg font-[500] dark:text-[#d9d9d9]">
                                    Currently this invoice is a <span className="text-black dark:text-white">DRAFT</span>
                                </p>
                                <p className="dark:text-[#d9d9d9]">
                                    The invoice must be finalized by the creator before payment can be made.
                                </p>
                            </>}
                        {invoice.status == "Finalized" &&
                            <>
                                <p className="text-lg font-[500] dark:text-[#d9d9d9]">
                                    Currently this invoice is <span className="text-black dark:text-white">FINALIZED</span>
                                </p>
                                <p className="dark:text-[#d9d9d9]">
                                    The invoice can no longer be modified, but can be canceled by the creator.
                                </p>
                            </>}
                        {invoice.status == "Canceled" &&
                            <>
                                <p className="text-lg font-[500] dark:text-[#d9d9d9]">
                                    Currently this invoice is <span className="text-black dark:text-white">CANCELED</span>
                                </p>
                                <p className="dark:text-[#d9d9d9]">
                                    The invoice creator has canceled this invoice. It cannot be reopened.
                                </p>
                            </>}
                        {invoice.status == "In Progress" &&
                            <>
                                <p className="text-lg font-[500] dark:text-[#d9d9d9]">
                                    Currently this invoice is <span className="text-black dark:text-white">IN PROGRESS</span>
                                </p>
                                <p className="dark:text-[#d9d9d9]">
                                    Only the user who paid the invoice can change its status to either "Completed" or "Disputed".
                                </p>
                            </>}
                        {invoice.status == "Disputed" &&
                            <>
                                <p className="text-lg font-[500] dark:text-[#d9d9d9]">
                                    Currently this invoice is <span className="text-black dark:text-white">DISPUTED</span>
                                </p>
                                <p className="dark:text-[#d9d9d9]">
                                    If you have not already done so please reach out to MuchMunchie support <a href="mailto:support@connectsiimply.com" className="text-black">support@connectsiimply.com</a> with details about why this invoice was disputed.
                                </p>
                            </>}
                        {invoice.status == "Completed (pending payout)" &&
                            <>

                                <p className="text-lg font-[500] dark:text-[#d9d9d9]">
                                    Currently this invoice is <span className="text-black dark:text-white">COMPLETED (pending payout)</span>
                                </p>
                                <p className="dark:text-[#d9d9d9]">
                                    The invoice has been completed and is pending payout by the MunchMuchie team.
                                </p>
                            </>}
                        {invoice.status == "Completed (paid out)" &&
                            <>
                                <p className="text-lg font-[500] dark:text-[#d9d9d9]">
                                    Currently this invoice is <span className="text-black dark:text-white">COMPLETED (paid out)</span>
                                </p>
                                <p className="dark:text-[#d9d9d9]">
                                    The invoice has been completed and has been paid out by the ConnectSiimply team.
                                </p>
                            </>}
                        <InvoiceStatusChangerModal invoice={invoice} />

                        {invoice.paidBy &&
                            <div>
                                <div className="text-lg font-[500] pb-2 text-center 800px:text-left">
                                    This invoice was paid by
                                </div>
                                <CompactProfile userId={invoice.paidBy} showFollowButton={false} />
                            </div>}
                        {isAuthenticated && invoice.status === "Finalized" && user._id !== invoice.createdBy ?
                            <div className="flex justify-center">

                                <button onClick={launchCheckoutFlow}
                                    className="text-nowrap inline-flex h-[35px] justify-center items-center px-4 border border-transparent text-md rounded-full text-black bg-[#FFFB00]">
                                    Pay ${invoice.totalCostInCAD}
                                </button>
                            </div> : <></>}
                    </div>
                    <div className="w-full py-4 shadow rounded-lg 800px:px-10 p-2">
                        <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] dark:text-[#d9d9d9] pb-2">
                            Access
                        </h1>
                        <p className="py-2 dark:text-[#d9d9d9]">
                            The following users have access to this invoice:
                        </p>
                        <div className="flex flex-col space-y-2">
                            {invoice.sharedWith.map((sharedUserId) => (
                                <CompactProfile userId={sharedUserId} showFollowButton={false} key={sharedUserId + "shared"} />
                            ))}
                        </div>
                        <InvoiceShareModal invoice={invoice} key={invoice._id + "sharemodal"} />


                    </div>
                </div>
            </div>
        </div >
    );
};

export default ManageInvoices;
import React from "react";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";
import ListJobApplications from "../../components/Jobs/ListJobApplications";
const ManageJobApplications = () => {
  const {isAuthenticated } = useSelector((state) => state.user);

  if (!isAuthenticated) {
    return <LoginPage />
  }

  return (
    <div>
      <div className={`w-[90%] mx-auto 800px:flex py-10`}>
        <ProfileSideBar active={`manage-job-applications`} />

        <ListJobApplications/>
      </div>
    </div>
  );
};
export default ManageJobApplications;

import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { IoMdClose } from 'react-icons/io'; // Import the close icon from react-icons
import { FaXTwitter } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { BiRepost } from "react-icons/bi";
import axios from 'axios';
import Cookies from 'js-cookie';
import { server } from "../../server";

function RepostModal({ post, userData, prependPost }) {
    const [open, setOpen] = useState(false);
    const [repostDescription, setRepostDescription] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const repost = () => {
        const token = Cookies.get('token');
        // Set up the authorization header with the token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };

        axios.post(`${server}/posts/repost`, {
            postId: post._id,
            repostDescription: repostDescription,
        }, config).then((res) => {
            prependPost(res.data);
            
        }).catch((err) => {
            console.error('Error reposting post:', err);
        });

        toast.success("Successfully reposted!");
        setOpen(false);
        setRepostDescription('');
    };

    return (
        <div>
            <span className='dark:text-[#d9d9d9] flex flex-row mx-2 my-2 cursor-pointer items-center' onClick={handleClickOpen}>
            <BiRepost className="mr-2 dark:text-[#FFFB00]" size={25}  />
            Repost
            </span>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ style: { overflowX: "hidden" }, className: 'w-full' }}
            >
                <DialogTitle id="alert-dialog-title" style={{ paddingRight: '48px' }}
                    className='dark:bg-[#262627] dark:text-[#d9d9d9]'>
                    Repost
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: '-5px',
                            top: '-5px',
                            
                        }}
                        className='text-black dark:text-[#FFFB00]'
                    >
                        <IoMdClose />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    className='bg-white dark:bg-[#262627]'>
                    <DialogContentText id="alert-dialog-description">
                        <p className="dark:text-white">
                            "{post?.description}"
                        </p>
                        <p className='dark:text-white'>
                            By: {userData?.name}
                        </p>
                        <br />
                        <textarea
                            className="w-full h-[100px] border border-solid border-[#FFFB00] p-2 mb-2 rounded-md"
                            placeholder="Add a message to your repost..."
                            value={repostDescription}
                            onChange={(e) => setRepostDescription(e.target.value)}

                        />
                        <button
                            onClick={repost}
                            className="w-full h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
                        >
                            Repost it!
                        </button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default RepostModal;
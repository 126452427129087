import React from "react";
import CommentList from "./CommentList";
import { AiOutlineClose } from "react-icons/ai";
import { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import Cookies from "js-cookie";
import CompactProfile from './CompactProfile';

const ChatSuggestedUsers = ({count = 10 }) => {
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                };
                const suggestedUsers = await axios.get(`${server}/user/suggest-users`, { headers });
                setSuggestedUsers(suggestedUsers.data.suggested.slice(0, count));
            } catch (err) {
                console.log("error", err);
            }
        };
        fetchData();
    }, []);
    if (!isOpen) {
        return <></>;
    }

    return (
        <div className="space-y-2 mt-6 bg-white dark:bg-[#262627] p-2 w-full rounded-sm flex flex-col " style={{ zIndex: "3" }} >
            <AiOutlineClose className="cursor-pointer absolute dark:text-[#FFFB00]" size="20" onClick={() => { setIsOpen(false) }} />
            <h2 className="font-bold text-center border-b-[1px] border-solid pb-[8px] dark:text-[#cecaca]">Who to follow</h2>
            {suggestedUsers.map((user) => (
                <CompactProfile key={user._id} userObject={user} showFollowButton={false} />
            ))}
        </div>
    );
};

export default ChatSuggestedUsers;
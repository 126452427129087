import React from 'react'
import { useLoaderData } from "react-router";
import { Link } from "react-router-dom";
import styles from "../styles/styles";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';
import Rating from "../components/Reviews/Ratings";
import { FaEye } from 'react-icons/fa';
import { Switch } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { CiSun } from 'react-icons/ci';
import { IoMoonOutline, IoSunnyOutline } from 'react-icons/io5';

const HomePage = () => {
  const loaderData = useLoaderData();
  const { isAuthenticated } = useSelector((state) => state.user);
  const [searchQuery, setSearchQuery] = useState("");
  const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") == "true");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim() === "") {
      return;
    }
    navigate(`/search?query=${searchQuery}`);

  };
  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'm';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k';
    }
    return num;
  };
  const updateDarkModeSwitch = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("darkMode", !darkMode);
    if (!darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    // window.location.reload();
  }


  return (
    <>
      <div
       className='800px:ml-[220px] ml-[60px] 800px:static absolute'> 
        {/* <Tooltip key="dark-mode-toggle" title="mode" placement='bottom' arrow> */}
        <span className='flex flex-row items-center dark:text-[#FFFB00]'> 
          <IoSunnyOutline size="20" className='dark:visible invisible' />
            <Switch
              checked={darkMode}
              onChange={updateDarkModeSwitch}
              inputProps={{ 'aria-label': 'dark mode' }}
              className=''
              size="large"
              color='primary'
            />
            <IoMoonOutline size="20" className='dark:invisible'/>
         </span> 
        {/* </Tooltip> */}
      </div>

      <div className=" flex flex-col justify-center min-h-[50svh] 800px:min-h-[60svh] mx-auto">
        <div className="text-center w-10/12 800px:w-9/12 mx-auto text-[25px] leading-[1.0] 800px:text-[60px] font-[900] dark:text-white">
          JOIN CONNECTS<span className="dot-i">I</span><span className="dot-i">I</span>MPLY A FREELANCE SOCIAL MEDIA <span className='text-[#FFFB00] outline-text'>MARKETPLACE</span></div>
        <div className="leading-[1.0] 800px:leading-[1.4] w-10/12 800px:w-9/12 font-archivo text-[15px] 800px:text-[20px] mx-auto text-center font-[500] mt-6 text-gray-400 dark:text-[#d9d9d9]">
        A marketplace  where Canadian creatives and businesses connect,<br/> network, and bring ideas to life.
        </div>
        {!isAuthenticated &&
          <div className='mx-auto mt-6'>
            <Link to={'/sign-up'} className="px-6 py-2 rounded-full bg-[#FFFB00] text-black font-archivoBlack text-2xl font-[600]">
              START NOW
            </Link>
          </div>}
        <form onSubmit={handleSubmit} className="flex items-center 800px:w-6/12 w-10/12 mx-auto mt-6">
          <div className="relative w-full">
            <input
              type="text"
              name="search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search ConnectSiimply"
              className="dark:text-[#d9d9d9] dark:bg-[#262627] appearance-none block w-full px-3 py-2 dark:border-0 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none"
            />
            <button type="submit" className='absolute right-3 top-1/2 transform -translate-y-1/2 text-[#d9d9d9]'>
              <FaSearch className="" size={20} />
            </button>
          </div>
        </form>
      </div>

      <div
        className={` flex`}
      >
        <div className={`w-[95%] mx-auto flex flex-col h-full mb-5`}>
          {/* list of tags and links in grid form */}
          <div className="grid 800px:grid-cols-3 1300px:grid-cols-4 grid-cols-1 gap-4">
            {loaderData?.serviceOfferings.map((offering) => (
              <Link key={offering._id} to={`/services/?id=${offering._id}`} className="flex flex-col items-center w-full cursor-pointer relative">
                <img src={`https://res.cloudinary.com/dljapfuhk/${offering?.media[0].mediaType}/upload/c_fill,dpr_2.0,f_auto,g_auto,h_200,q_auto,w_400/v1/${offering?.media[0]?.publicId}`} alt={offering.title} className="w-full h-[200px] object-cover rounded-sm" />
                <div className='w-full flex flex-row items-center justify-between px-2'>
                  <span className='font-[600] dark:text-[#FFFB00]'>{offering.skillTag?.name}</span>

                  <span className='flex flex-row items-center'>

                    {offering.averageRating > 0 &&
                      <span className='flex flex-row items-center'>
                        <Rating rating={offering.averageRating ?? "0.0"} />
                        <span className="pl-2 dark:text-white">{offering.averageRating?.toFixed(1)}</span>
                      </span>
                    }
                    <FaEye size={20} className='ml-2 dark:text-[#FFFB00]' />
                    <span className="pl-2 dark:text-white">{formatNumber(offering.viewCount)}</span>
                  </span>
                </div>
              </Link>
            ))}

          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { BiBadgeCheck } from "react-icons/bi";
import Markdown from "react-markdown";
import { CiLocationOn } from "react-icons/ci";
import Ratings from "./../Reviews/Ratings.jsx";



const JobOfferingCard = ({ offering, urlParams, preventScrollReset = false, openAdView }) => {
    const location = useLocation();
    let newUrlParams = new URLSearchParams(urlParams);
    newUrlParams.delete("id");
    newUrlParams.delete("source");
    const newUrlParamsString = newUrlParams.toString().length > 0 ? "&" + newUrlParams.toString() : "";


    return (
        <div className={`flex flex-col w-full rounded-lg p-2 cursor-pointer relative max-w-[600px] mx-auto border-2 border-[#e8e6e6] dark:border-[#5c5757]`}>
            <Link onClick={openAdView} to={`/jobs?id=${offering._id}${newUrlParamsString}${!urlParams ? "&source=search" : ""}`}
                state={{ from: location.pathname, search: location.search }} preventScrollReset={preventScrollReset}>
                <div className="pl-3">
                    <h2 className=" text-lg font-[700] text-black dark:text-[#FFFB00]">
                        {offering?.title}
                    </h2>
                    <p className="flex flex-row items-center  text-sm text-black dark:text-white">
                        {offering?.location ? (<><CiLocationOn className="text-black dark:text-white" /> {offering?.location}</>) : ' '}
                    </p>


                    <span className="flex flex-row items-center">
                        <div className="flex flex-col">
                            <div className="flex items-center text-left">
                                <h5 className={`text-md font-[500] dark:text-white`}>{offering.account.name ?? "Loading..."}</h5>
                                {offering.account.isPremium && (<BiBadgeCheck className="text-black dark:text-[#FFFB00]" size="20" />)}

                                <div className="flex flex-row items-center text-sm text-black dark:text-white font-medium text-left">
                                    {offering.averageRating > 0 && (
                                        <span className="flex flex-row items-center ml-2">
                                            <Ratings rating={offering.averageRating ?? "0.0"} />
                                            <span className="pl-2">{offering.averageRating?.toFixed(1)}</span>
                                        </span>)}
                                </div>
                            </div>

                        </div>
                    </span>


                    <div className="my-1 flex flex-wrap gap-y-2">

                        <span className="inline-block rounded-full px-4 py-2 text-sm  mr-2 bg-[#FFFB00] ">{offering?.employment_type.replace("-", " ")}</span>
                        <span className="inline-block rounded-full px-4 py-2 text-sm  mr-2 bg-[#FFFB00] ">{offering?.work_style.replace("-", " ")}</span>

                        {offering?.salary && (
                            <span className="inline-block rounded-full px-4 py-2 text-sm  mr-2 bg-[#FFFB00] ">{offering.salary}</span>
                        )}
                    </div>
                </div>
                <div className="px-5">
                    <Markdown className="text-gray-700 dark:prose-invert dark:text-white prose max-w-full line-clamp-3" disallowedElements={["a"]}>
                        {offering.responsibilities}
                    </Markdown>
                </div>

            </Link>
        </div>
    );
};

export default JobOfferingCard;
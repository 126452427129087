import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";
import Cookies from 'js-cookie';
import { makeStyles } from "@material-ui/core/styles";
import JobApplicationDeletionModal from "./JobApplicationDeletionModal";

const ListJobApplications = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user, isAuthenticated } = useSelector((state) => state.user);
    
    useEffect(() => {
        setLoading(true);
        setData([]);
        if (isAuthenticated) {
            // Retrieve the token from the cookies
            const token = Cookies.get('token');

            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            axios.get(`${server}/jobs/applications`, config).then((res) => {

                setData(res.data.jobApplications);

                setLoading(false);
            })
        }

    }, [isAuthenticated, user]);

    let columns = [
        {
            field: "title",
            headerName: "Job",
            minWidth: 180,
            flex: 1,
            sortable: false,
            disableColumnMenu: true, // Disable the column menu for this column
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    lineHeight: '1.1',
                }}
                    className="dark:text-white">
                    <a href={`/jobs?id=${params.id}`} target="_blank" rel="noreferrer">
                        {params.value}
                    </a>
                </div>
            )
        },
        {
            field: "date",
            headerName: "Application Date",
            minWidth: 200,
            flex: .4,
            sortable: false,
            align: 'center', // Center align the column content
            headerAlign: 'center', // Center align the column header
            disableColumnMenu: true, // Disable the column menu for this column
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    lineHeight: '1.1',
                }}
                    className="dark:text-white">
                    {params.value}
                </div>
            )



        },
        {
            field: "Delete",
            flex: 0.4,
            minWidth: 100,
            headerName: "",
            type: "number",
            sortable: false,
            align: 'center', // Center align the column content
            headerAlign: 'center', // Center align the column header
            disableColumnMenu: true, // Disable the column menu for this column
            renderCell: (params) => {
                return (
                    <JobApplicationDeletionModal id={params.id} title={params.row.title} setData={setData} />
                );
            },
        },
    ];
    const row = [];

    data &&
        data.forEach((item) => {
            row.push({
                id: item._id,
                title: item.title,
                date: new Date(parseInt(item.applicants[0].date)).toLocaleString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                }),
            });
        });

    const dataGridStyle = makeStyles({
        root: {
            '& .MuiDataGrid-overlay': {
                backgroundColor: '#000', // Your desired background color

            },

        },
    });
    const classes = dataGridStyle();
    return (
        <div className="w-full py-4 800px:mx-4 shadow rounded-lg 800px:px-10 my-4 800px:my-0">
            <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] dark:text-[#d9d9d9] pb-2">
            Job applications
            </h1>
            <p className="text-center dark:text-[#e0e0e0] p-4">These are the jobs you have clicked apply on, this list is used for personal tracking and has no impact on your application</p>

            <div style={{ transform: 'rotateZ(0deg)' }} className="">
                <DataGrid
                    rows={row}
                    columns={columns}
                    pageSize={5}
                    rowHeight={60}
                    disableSelectionOnClick
                    localeText={{ noRowsLabel: loading ? "Loading... please wait" : 'Nothing yet!' }}
                    autoHeight
                    disableRowSelectionOnClick
                    className={`${document.documentElement.classList.contains('dark') ? classes.root : ''} dark:text-white`}
                />
            </div>
            <div className="flex justify-center px-4 pt-4">
            </div>
        </div>
    );
};

export default ListJobApplications;

import React from "react";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "@material-ui/core";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { MdTitle } from "react-icons/md";
import OfferingDeletionModal from "../../components/Offerings/OfferingDeletionModal";
import ServiceUploadWidget from "../../components/Services/ServiceUploadWidget";
import { TextareaAutosize } from "@material-ui/core";
import Select, { components, MultiValueGenericProps } from 'react-select';
import makeAnimated from 'react-select/animated';
import Tooltip from '@atlaskit/tooltip';
import { FaArrowLeft } from "react-icons/fa6";
import { useLoaderData } from "react-router-dom";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";
import CompactProfile from "../../components/Social/CompactProfile";

const MultiValue = (props) => {
    return (
        <Tooltip content={props.data.toolTip}>
            <components.MultiValue {...props} />
        </Tooltip>
    );
};

const animatedComponents = makeAnimated({ MultiValue: MultiValue });

const ServiceCRUD = () => {
    const { user, isAuthenticated } = useSelector((state) => state.user);
    const { jobId } = useParams();
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [creator, setCreator] = useState(user._id);
    const [salary, setSalary] = useState("");
    const [location, setLocation] = useState("");
    const [employmentType, setEmploymentType] = useState("");
    const [workStyle, setWorkStyle] = useState("");
    const [responsibilities, setResponsibilities] = useState("");
    const [qualifications, setQualifications] = useState("");
    const [benefits, setBenefits] = useState("");
    const [link, setLink] = useState("");
    const [published, setPublished] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const loaderData = useLoaderData();

    useEffect(() => {
        if (jobId !== "new") {
            const jobOffering = loaderData.jobOffering;
            setCreator(jobOffering.userId);
            setTitle(jobOffering.title);
            setSalary(jobOffering.salary);
            setLocation(jobOffering.location);
            setEmploymentType({value:jobOffering.employment_type, label:jobOffering.employment_type.replace("-"," ")});
            setWorkStyle({value:jobOffering.work_style, label:jobOffering.work_style.replace("-"," ")});
            setResponsibilities(jobOffering.responsibilities);
            setQualifications(jobOffering.qualifications);
            setBenefits(jobOffering.benefits);
            setLink(jobOffering.link);
            setPublished(jobOffering.published);
        }
    }, []);

    useEffect(() => {
        axios.get(`${server}/search/users/${searchQuery}?maxResults=5`).then((res) => {
            // console.log(res.data);
            setSearchResults(res.data.users);
        });
    }, [searchQuery]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!user) return toast.error("Please login!");
            if (!title) return toast.error("A title is required!");
            if (!employmentType || employmentType.length === 0) return toast.error("An employment type is required!");
            if (!workStyle || workStyle.length === 0) return toast.error("A work style is required!");

            const token = Cookies.get('token');
            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };

            await axios.put(`${server}/jobs/ad/${jobId}`, {
                userId: creator,
                title: title,
                salary: salary,
                location: location,
                employment_type: employmentType.value,
                work_style: workStyle.value,
                responsibilities: responsibilities,
                qualifications: qualifications,
                benefits: benefits,
                link: link,
                published: published

            }, config);
            if (jobId === "new") {
                toast.success("Job ad created successfully!");
            } else {
                toast.success("Job ad updated successfully!");
            }
            navigate(`/manage-jobs/`);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="w-[90%] mx-auto 800px:flex py-10">
            <div>
                <ProfileSideBar active={`manage-jobs`} />
            </div>

            <div className="w-full py-4 800px:mx-4 shadow rounded-lg px-4  my-4 800px:my-0">
                <div className="flex justify-between mx-2 mt-2 mb-5 ">
                    <Link to={`/manage-jobs/`} className="text-black dark:text-[#FFFB00] flex items-center"><FaArrowLeft className="pr-1 text-black dark:text-[#FFFB00]" /> Back to My Jobs</Link>
                    {(jobId !== "new") && (
                        <OfferingDeletionModal title={title} id={jobId} type="jobs" />
                    )}
                </div>
                {jobId === "new" ? (
                    <p className="text-center text-2xl mb-5 dark:text-[#d9d9d9]">Creating a new job ad</p>
                ) : (

                    <p className="text-center text-2xl mb-5 dark:text-[#d9d9d9]">Editing an existing Job ad</p>
                )}

                <div>
                    {user?.role == "Admin" && (
                        <div className="flex flex-col border-2 border-solid border-red-500 p-2 rounded-md mb-4">
                            <h2 className="text-[red] text-lg">Admin only</h2>
                            <div>
                                <p className="text-[red]">Current ad creator</p>
                                <CompactProfile userId={creator} showFollowButton={false} />

                                <p className="text-[red]">Select a different ad creator</p>
                                <input
                                    type="text"
                                    name="search"
                                    value={searchQuery}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search for a name..."
                                />
                                <div className={`mt-2 space-y-2 ${searchQuery.length === 0 ? 'hidden' : ''}`}>
                                    {searchResults.map((user) => (
                                        <div className='flex flex-row justify-between items-center' key={user._id}>
                                            <CompactProfile key={user._id} userObject={user} showFollowButton={false} />

                                            <button
                                                className="w-[200px] h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-lg rounded-full text-black bg-[#FFFB00]"
                                                onClick={() => { setCreator(user._id); setSearchQuery('') }}>
                                                set as creator
                                            </button>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Title<span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="title"
                        value={title}
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter your service title..."
                    />
                    <br />
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Salary
                    </label>
                    <input
                        type="text"
                        name="salary"
                        value={salary}
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setSalary(e.target.value)}
                        placeholder="$25,000 - $30,000"
                    />
                    <br />
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Location
                    </label>
                    <input
                        type="text"
                        name="location"
                        value={location}
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setLocation(e.target.value)}
                        placeholder="Toronto, ON"
                    />
                    <br />

                    <label className="pb-2 dark:text-[#FFFB00]">
                        Employment Type<span className="text-red-500">*</span>
                    </label>
                    <Select
                        value={employmentType}
                        onChange={(e) => setEmploymentType(e)}
                        options={["Full time", "Part time", "Contract", "On call"].map((option) => ({ value: option.replace(" ","-"), label: option }))}
                        theme={(theme) => (
                            {
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: "#FFFB00",
                                    primary25: '#FFFECC',
                                    primary50: '#FFFECC',
                                },
                            })}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                marginTop: '10px',
                                color: '#000'
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                color: 'black'

                            }),

                        }}
                    />
                    <br />

                    <label className="pb-2 dark:text-[#FFFB00]">
                        Work Style<span className="text-red-500">*</span>
                    </label>
                    <Select
                        value={workStyle}
                        onChange={(e) => setWorkStyle(e)}
                        options={["Remote", "In person", "Hybrid"].map((option) => ({ value: option.replace(" ","-"), label: option }))}
                        theme={(theme) => (
                            {
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: "#FFFB00",
                                    primary25: '#FFFECC',
                                    primary50: '#FFFECC',
                                },
                            })}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                marginTop: '10px',
                                color: '#000'
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                color: 'black'

                            }),

                        }}
                    />
                    {/* <select
                        name="workStyle"
                        value={workStyle}
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setWorkStyle(e.target.value)}
                    >
                        <option value="">Select a work style</option>
                        <option value="Remote">Remote</option>
                        <option value="In-person">In-person</option>
                        <option value="Hybrid">Hybrid</option>
                    </select> */}
                    <br />
                    <p className="text-sm dark:text-[#e0e0e0]">
                        Markdown formatting is supported for the following fields
                    </p>
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Responsibilities
                    </label>
                    <TextareaAutosize
                        minRows={3}
                        type="text"
                        name="responsibilities"
                        value={responsibilities}
                        className="mt-2 appearance-none block w-full p-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setResponsibilities(e.target.value)}
                    />
                    <br />
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Qualifications
                    </label>
                    <TextareaAutosize
                        minRows={3}
                        type="text"
                        name="qualifications"
                        value={qualifications}
                        className="mt-2 appearance-none block w-full p-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setQualifications(e.target.value)}
                    />
                    <br />
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Benefits
                    </label>
                    <TextareaAutosize
                        minRows={3}
                        type="text"
                        name="benefits"
                        value={benefits}
                        className="mt-2 appearance-none block w-full p-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setBenefits(e.target.value)}
                    />
                    <br />
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Link to application<span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="link"
                        value={link}
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setLink(e.target.value)}
                        placeholder="https://www.example.com"
                    />
                    <br />

                    <div className="flex items-center dark:text-[#FFFB00]">
                        <input
                            type="checkbox"
                            name="published"
                            id="published"
                            checked={published}
                            className="h-4 w-4 text-blue-600 accent-[#FFFB00] border-gray-300 rounded"
                            onChange={(e) => setPublished(e.target.checked)}
                        />
                        <label className="pl-2" htmlFor="published">
                            Published
                        </label>
                    </div>
                </div>
                <button
                    onClick={handleSubmit}
                    className="mt-5 w-full 800px:max-w-[50%] mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
                >
                    {jobId === "new" ? (
                        <>Create</>
                    ) : (
                        <>Update</>
                    )}
                </button>
            </div>


        </div>
    );
};
export default ServiceCRUD;

import React, { useState } from "react";
import {
  AiOutlineCamera,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../server";
import {
  loadUser,
  updateUserInformation,
} from "../../redux/actions/user";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from 'js-cookie';
import InappropriateContentDialog from "../Layout/InappropriateContentDialog";

const ProfileContent = () => {
  const { user, error } = useSelector((state) => state.user);
  const [name, setName] = useState(user && user.name);
  const [email, setEmail] = useState(user && user.email);
  const [title, setTitle] = useState(user && user.title);
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const [bio, setBio] = useState(user && user.bio);
  const [publicLocation, setPublicLocation] = useState(user && user?.publicLocation);
  const [linkedinURL, setLinkedinURL] = useState(user && user?.linkedinURL);
  const [twitterHandle, setTwitterHandle] = useState(user && user?.twitterHandle);
  const [instagramHandle, setInstagramHandle] = useState(user && user?.instagramHandle);
  const [pinterestHandle, setPinterestHandle] = useState(user && user?.pinterestHandle);
  const [portfolioLink, setPortfolioLink] = useState(user && user?.portfolioLink);
  const [chatEmailNotifications, setChatEmailNotifications] = useState(user && user?.chatEmailNotifications);
  const [InappropriateContentDialogOpen, setInappropriateContentDialogOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }

  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Regex the bio for emails and phone numbers and prevent them from being saved
    const emailRegex = /\S+@\S+\.\S+/;
    const phoneRegex = /(\d{3})\D*(\d{3})\D*(\d{4})/;
    const emailMatch = emailRegex.test(bio);
    const phoneMatch = phoneRegex.test(bio);
    if (emailMatch || phoneMatch) {
      setInappropriateContentDialogOpen(true);
      return;
    }
    // Dispatch the action to update the user information
    dispatch(updateUserInformation(name, email, title, phoneNumber, bio, publicLocation, chatEmailNotifications, linkedinURL, twitterHandle, instagramHandle, pinterestHandle, portfolioLink));
    toast.success("Profile updated successfully!");
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        // Retrieve the token from the cookies
        const token = Cookies.get('token');
        // Set up the authorization header with the token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        };
        axios
          .put(
            `${server}/user/update-avatar`,
            { avatar: reader.result },
            config
          )
          .then((response) => {
            dispatch(loadUser());
            toast.success("avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <InappropriateContentDialog open={InappropriateContentDialogOpen} setOpen={setInappropriateContentDialogOpen} />
      <div className="w-full my-4 800px:my-0 py-4 800px:mx-4 shadow rounded-lg px-8">
        <div className="flex justify-center w-full">
          <div className="relative">
            <img
              src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${user?.avatar?.public_id}.jpg`}
              className="w-[150px]  min-w-[150px] h-[150px] rounded-full object-cover border-[3px] border-[#FFFB00]"
              alt=""
              hidden={!user}
            />
            <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[5px] right-[5px]">
              <input
                type="file"
                id="image"
                className="hidden"
                onChange={handleImage}
              />
              <label htmlFor="image">
                <AiOutlineCamera />
              </label>
            </div>
          </div>
        </div>
        <br />
        <form onSubmit={handleSubmit} aria-required={true} >
          <div className="w-full 800px:flex block pb-3 800px:space-x-8 space-y-4 800px:space-y-0">
            <div className=" w-[100%] 800px:w-[50%] ">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00] "
              >
                Full Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  autoComplete="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>
            </div>
            <div className=" w-[100%] 800px:w-[50%]">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00] "
              >
                Publicly shared Location e.g Toronto, Ontario
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="location"
                  autoComplete="text"
                  value={publicLocation}
                  onChange={(e) => setPublicLocation(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>

            </div>
          </div>
          <div className="w-full 800px:flex block pb-3 800px:space-x-8 space-y-4 800px:space-y-0">
            <div className=" w-[100%] 800px:w-[50%] ">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00] "
              >
                Title
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="title"
                  autoComplete="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>
            </div>
            <div className=" w-[100%] 800px:w-[50%]">
              <label
                htmlFor="phnoe-number"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00] "
              >
                Phone number
              </label>
              <div className="mt-1">
                <input
                  type="tel"
                  name="phone-number"
                  autoComplete="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>
            </div>
          </div>

          <div className="w-full 800px:flex block pb-3 800px:space-x-8 space-y-4 800px:space-y-0">
            <div className=" w-[100%] 800px:w-[50%] ">
              <label
                htmlFor="bio"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00] "
              >
                Bio
              </label>
              <div className="mt-1">
                <textarea
                  type="text"
                  name="bio"
                  autoComplete="text"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>
            </div>
            <div className=" w-[100%] 800px:w-[50%]">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00]"
              >
                Email/Username
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>
              <div className="flex items-center pt-2">
                <input
                  type="checkbox"
                  id="notifications-me"
                  className="h-4 w-4 accent-[#FFFB00] border-gray-300 rounded "
                  checked={chatEmailNotifications}
                  onChange={(e) => setChatEmailNotifications(e.target.checked)}
                />
                <label className="ml-2 block text-sm text-gray-900 dark:text-[#FFFB00]" htmlFor="notifications-me">Email Notifications</label>

              </div>
            </div>

          </div>
          <div className="w-full 800px:flex block pb-3 800px:space-x-8 space-y-4 800px:space-y-0">
            <div className=" w-[100%] 800px:w-[50%] ">
              <label
                htmlFor="linkedinURL"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00]"
              >
                Linkedin Profile URL
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="linkedinURL"
                  autoComplete="text"
                  value={linkedinURL}
                  onChange={(e) => setLinkedinURL(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>
            </div>
            <div className=" w-[100%] 800px:w-[50%] ">
              <label
                htmlFor="twitter-handle"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00]"
              >
                Twitter/X handle e.g @connectsiimply
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="twitter-handle"
                  autoComplete="text"
                  value={twitterHandle}
                  onChange={(e) => setTwitterHandle(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>
            </div>
          </div>
          <div className="w-full 800px:flex block pb-3 800px:space-x-8 space-y-4 800px:space-y-0">
            <div className=" w-[100%] 800px:w-[50%] ">
              <label
                htmlFor="instagram-handle"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00]"
              >
                Instagram handle e.g @connectsiimply
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="instagram-handle"
                  autoComplete="text"
                  value={instagramHandle}
                  onChange={(e) => setInstagramHandle(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>
            </div>
            <div className=" w-[100%] 800px:w-[50%] ">
              <label
                htmlFor="pinterest-handle"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00]"
              >
                Pinterest handle e.g "connectsiimply"
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="pinterest-handle"
                  autoComplete="text"
                  value={pinterestHandle}
                  onChange={(e) => setPinterestHandle(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>
            </div>
          </div>

          <div className="w-full 800px:flex block pb-3 800px:space-x-8 space-y-4 800px:space-y-0">
            <div className=" w-[100%] 800px:w-[50%] ">
              <label
                htmlFor="instagram-handle"
                className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00]"
              >
                Portfolio Link (displayed on bio)
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="portfolio-link"
                  autoComplete="text"
                  value={portfolioLink}
                  onChange={(e) => setPortfolioLink(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                />
              </div>
            </div>
            <div className=" w-[100%] 800px:w-[50%] ">

            </div>
          </div>
          <button
            id="profile-update-submit-button"
            type="submit"
            className="w-full 800px:max-w-[50%] mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
          >
            Save Changes
          </button>
        </form>
      </div>
    </>
  );
};






export default ProfileContent;

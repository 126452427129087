import React from "react";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import ServiceOfferingCard from "../../components/Services/ServiceOfferingCard";
import { useSearchParams } from "react-router-dom";
import ServiceOfferingDetails from "../../components/Services/ServiceOfferingDetails";
import { useLoaderData } from "react-router";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { components } from 'react-select';
import Tooltip from '@atlaskit/tooltip';
import { useNavigate } from "react-router-dom";
import OfferingReviews from "../../components/Reviews/OfferingReviews";
import { Slider } from '@material-ui/core'
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";
import { Backdrop, Button } from "@material-ui/core";
import CompactProfile from "../../components/Social/CompactProfile";
import JobOfferingCard from "../../components/Jobs/JobOfferingCard";
import { FaSearch } from "react-icons/fa";
import EmploymentTypeSelect from "../../components/Jobs/EmploymentTypeSelect";
import WorkStyleSelect from "../../components/Jobs/WorkStyleSelect";
import JobOfferingDetails from "../../components/Jobs/JobOfferingDetails";

const MultiValue = (props) => {
  return (
    <Tooltip content={props.data.toolTip}>
      <components.MultiValue {...props} />
    </Tooltip>
  );
};
const animatedComponents = makeAnimated({ MultiValue: MultiValue });

const ServicesHome = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const jobData = useLoaderData();
  const [jobOfferings, setJobOfferings] = useState(jobData?.jobOfferings);

  useEffect(() => {
    setJobOfferings(jobData?.jobOfferings);
  }, [jobData]);
  
  const [location, setLocation] = useState(searchParams.get("location") ?? "");
  const [workStyle, setWorkStyle] = useState(searchParams.get("work_style") ?? "Any");
  const [employmentType, setEmploymentType] = useState(searchParams.get("employment_type") ?? "Any");

  const [searchQuery, setSearchQuery] = useState(searchParams.get("query") ?? "");
  const [mobileFilterViewable, setMobileFilterViewable] = useState(false);
  const [adOpen, setAdOpen] = useState(false);
  const navigate = useNavigate();
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const { isAuthenticated } = useSelector((state) => state.user);
  let [canReview, setCanReview] = useState(false);

  const fetchData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${Cookies.get('token')}`,
      };
      const suggestedUsers = await axios.get(`${server}/user/suggest-users`, { headers });
      setSuggestedUsers(suggestedUsers.data.suggested);
    } catch (err) {
      console.log("error", err);
    }
  };


  useEffect(() => {
    if (searchParams.get("location")) {
      setLocation(searchParams.get("location"));
    }
    if (searchParams.get("employment_type")) {
      setEmploymentType(searchParams.get("employment_type"));
    }
    if (searchParams.get("work_style")) {
      setWorkStyle(searchParams.get("work_style"));
    }

    fetchData();

  }, [])
  useEffect(() => {
    if (searchParams.get("id")) {
      setAdOpen(true);
      setCanReview(false);
      if (searchParams.get("source")) {
        setSearchParams((prev) => {
          prev.delete("source");

          if (location) {
            prev.set("location", location);
          }
          if (employmentType) {
            prev.set("employment_type", employmentType);
          }
          if (workStyle) {
            prev.set("work_style", workStyle);
          }

          return prev;
        }, { replace: true });
      }

    }
  }, [searchParams.get("id"), searchParams.get("source")]);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  const debouncedUpdate = useCallback(
    debounce((name, value) => {
      if (searchParams.get(name) || value) {
        setSearchParams((prev) => {
          if (value == "Any" || value == "") {
            prev.delete(name);
          } else {
            prev.set(name, value);
          }

          prev.delete("id");

          return prev;
        }, { replace: true });
      }
    }, 300),
    []
  );

  const setAverageRating = (offeringId, rating)  => {
    setJobOfferings((oldOfferings) => {
      const newOfferings = oldOfferings.map((offering) => {
        if (offering._id == offeringId) {
          offering.averageRating = rating;
        }
        return offering;
      });
      return newOfferings;
    });
  }
  const visibleOfferings = jobOfferings.filter(offering => !offering.hidden);

  return (
    <div className="flex 800px:flex-row flex-col-reverse justify-center 800px:space-x-2 800px:py-6 800px:mt-6">
      <div className={` block px-2 pb-10 space-y-2 max-w-[600px] w-full`}>
        {!visibleOfferings || visibleOfferings.length == 0 ?
          <div className="text-center mt-4 dark:text-[#e0e0e0]">
            No results found
          </div> : ""
        }

        {visibleOfferings?.map((offering, index) => {
          return <JobOfferingCard offering={offering} key={offering._id + " card"} urlParams={searchParams} preventScrollReset={true} openAdView={() => setAdOpen(true)} />;
        })}
      </div>
      <div className={`800px:w-[50%] w-full max-w-[600px]`}>
        <div className={`p-4 m-2 rounded-lg bg-[#f8f8f8] dark:bg-[#262627] border-2 border-[#f2f2f2] dark:border-[#5c5757]`}>
          <div className="flex flex-row 800px:flex-col items-center 800px:items-start">
            <IoIosArrowDropdown size={35} className={`mr-2 800px:hidden dark:text-white ${mobileFilterViewable ? "hidden" : ""}`} onClick={() => setMobileFilterViewable((oldState) => !oldState)} />
            <IoIosArrowDropup size={35} className={`mr-2 800px:hidden dark:text-white ${!mobileFilterViewable ? "hidden" : ""}`} onClick={() => setMobileFilterViewable((oldState) => !oldState)} />
            <h2 className="text-xl font-medium py-4 800px:block hidden dark:text-[#cecaca]">Search Jobs</h2>
            <div className="relative w-full">
              <input
                placeholder="Software engineer"
                type="text"
                value={searchQuery}
                onChange={(e) => { setSearchQuery(e.target.value); debouncedUpdate("query", e.target.value) }}

                className="w-full appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00] placeholder-bold"
              />
              <span className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-300'>
                <FaSearch className="" size={20} />
              </span>
            </div>
          </div>

          <span className={`${mobileFilterViewable ? "block" : "hidden"} 800px:block`}>
            <h2 className="text-xl font-medium py-4 dark:text-[#cecaca]">Location</h2>
            <input
              placeholder="Toronto, Ontario"
              type="text"
              value={location}
              onChange={(e) => { setLocation(e.target.value); debouncedUpdate("location", e.target.value) }}
              className="w-full appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00] placeholder-bold"
            />
            <h2 className="text-xl font-medium py-4 dark:text-[#cecaca]">Work Style</h2>
            <WorkStyleSelect debouncedUpdate={debouncedUpdate} initialWorkStyle={searchParams.get("work_style") ?? "Any"} />

            <h2 className="text-xl font-medium py-4 dark:text-[#cecaca]">Employment Type</h2>
            <EmploymentTypeSelect debouncedUpdate={debouncedUpdate} initialEmploymentType={searchParams.get("employment_type") ?? "Any"} />


            {isAuthenticated &&
              <>
                <h2 className="text-xl font-medium py-4 800px:block hidden dark:text-[#cecaca]">Follow Recommendations</h2>
                <span className="space-y-2 800px:block hidden">
                  {suggestedUsers.map((user) => (
                    <CompactProfile key={user._id} userObject={user} />
                  ))}
                </span>
              </>}
          </span>
          {searchParams.get("id") && adOpen &&
            <div className="fixed h-full bg-white dark:bg-[#27282A] border border-gray-200 dark:border-[#837c7c] overflow-y-scroll top-0 z-30 right-0 800px:w-6/12 w-11/12" key={searchParams.get("id")}>

              <JobOfferingDetails key={searchParams.get("id") + "details"} clickedApply={()=>{setCanReview(true)}} jobOfferingData={jobOfferings?.find(job => job._id == searchParams.get("id"))} closeAdView={() => { setAdOpen(false) }} />
              <OfferingReviews key={searchParams.get("id") + " review"} offeringId={searchParams.get("id")} type="jobs" canReview={canReview} setAverageRating={setAverageRating} averageRating={jobOfferings?.find(job => job._id == searchParams.get("id")).averageRating}/>

            </div>
          }
        </div>
      </div>
      <Backdrop open={adOpen && searchParams.get("id")} onClick={() => setAdOpen(false)} style={{ zIndex: 10 }} invisible={true} />
    </div>

  );
};
export default ServicesHome;

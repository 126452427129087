import React from "react";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";
import { useSelector } from "react-redux";
import LoginPage from "../Login";
import CompactProfile from "../../components/Social/CompactProfile";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const ProfilePage = () => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    setData([]);
    if (isAuthenticated) {
      // Retrieve the token from the cookies
      const token = Cookies.get('token');

      // Set up the authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      };
      axios.get(`${server}/jobs/applicants/${id}`, config).then((res) => {

        setData(res.data.jobApplicants);

      })
    }

  }, [isAuthenticated, user]);
  if (!isAuthenticated) {
    return <LoginPage />
  }
  return (
    <div>
      <div className={`w-[90%] mx-auto 800px:flex py-10`}>
        <ProfileSideBar active={"manage-jobs"} />
        <div className="w-full py-4 800px:mx-4 shadow rounded-lg px-10 my-4 800px:my-0">
        <Link to={`/manage-jobs/`} className="text-black dark:text-[#FFFB00] flex items-center"><FaArrowLeft className="pr-1 text-black dark:text-[#FFFB00]" /> Back to My Jobs</Link>

          <div className="800px:w-[50%] mx-auto">
            <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] dark:text-[#d9d9d9] pb-2">
              {data.title}
            </h1>
            {!data || data.applicants?.length === 0 ? (
              <div className='dark:text-[#d9d9d9] text-center'>
                No user has clicked the Apply button on your job posting yet.
              </div>) :
              <p className="dark:text-[#d9d9d9]">
                The following users clicked the Apply button on your job posting.
              </p>}
            {data?.applicants?.map((click_event) => {
              return (
                <div className="p-2 forum-post" key={click_event.userId}>
                  <CompactProfile userId={click_event.userId} showFollowButton={false} />
                </div>
              )
            }

            )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;

import axios from "axios";
import React from "react";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { server } from "../../server";
import Cookies from 'js-cookie';
import Button from '@material-ui/core/Button';
import Markdown from 'react-markdown';
import { BiBadgeCheck } from "react-icons/bi";
import ShareModal from "../Jobs/ShareModal";
import { Typography } from "@material-ui/core";
import { IoMdClose } from "react-icons/io";

const ServiceOfferingDetails = ({ jobOfferingData, closeAdView, clickedApply }) => {
    const { isAuthenticated } = useSelector((state) => state.user);

    const logJobOfferingApplyClick = () => {
        if (isAuthenticated) {
            const token = Cookies.get('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            axios.post(`${server}/jobs/ad/${jobOfferingData._id}/click`, {}, config).then(res => {
                // console.log(res.data)
                clickedApply();
                
            }
            ).catch(err => {
                console.log(err)
            })
        }
    }
    return (
        <>

            <div className="relative p-2 dark:text-white">
                <ShareModal offering={jobOfferingData} />
                <IoMdClose size={25} className="ml-2 my-2 cursor-pointer absolute top-0 left-0 dark:text-[#FFFB00]" onClick={closeAdView} />

                <div className="shadow-sm border border-gray-200 dark:border-[#837c7c] p-3 mt-10 ml-2 800px:mr-[20%] mr-[10%] rounded-xl">
                    <div className="mt-2 mx-auto flex items-center">
                        <h5 className="text-2xl font-bold dark:text-white">{jobOfferingData?.account.name ?? "Loading..."}</h5>
                        {jobOfferingData?.account.isPremium && (
                            <BiBadgeCheck size="27" className="dark:text-[#FFFB00]" />
                        )}

                    </div>
                    <div className="mt-2 mx-auto flex items-center">
                        <p className="flex text-xl font-bold dark:text-[#FFFB00]">Job title: </p>
                        <p className="flex pl-1 text-lg"> {jobOfferingData?.title}</p>
                    </div>
                    <div className={`mt-2 mx-auto flex items-center ${jobOfferingData.location ? "" : "hidden"}`}>
                        <p className="flex text-xl font-bold dark:text-[#FFFB00]">Location: </p>
                        <p className="flex pl-1 text-lg"> {jobOfferingData?.location}</p>
                    </div>
                    <div className={`mt-2 mx-auto flex items-center ${jobOfferingData.employment_type ? "" : "hidden"}`}>
                        <p className="flex text-xl font-bold dark:text-[#FFFB00]">Employment: </p>
                        <p className="flex pl-1 text-lg"> {jobOfferingData?.employment_type.replace("-", " ")}</p>
                    </div>
                    <div className={`mt-2 mx-auto flex items-center ${jobOfferingData.work_style ? "" : "hidden"}`}>
                        <p className="flex text-xl font-bold dark:text-[#FFFB00]">Working style: </p>
                        <p className="flex pl-1 text-lg"> {jobOfferingData?.work_style.replace("-", " ")}</p>
                    </div>
                </div>
            </div>

            {jobOfferingData?.responsibilities?.length > 0 && (
                <div className="p-4">
                    <h2 className="text-lg py-1 mb-2 bg-[#FFFB00] text-black rounded-full text-center inline-block px-4">Responsibilities</h2>
                    <Markdown className="prose text-black dark:prose-invert dark:text-white pb-2">{jobOfferingData?.responsibilities}</Markdown>
                </div>
            )}

            {jobOfferingData?.qualifications?.length > 0 && (
                <div className="p-4">
                    <h2 className="text-lg py-1 mb-2 bg-[#FFFB00] text-black rounded-full text-center inline-block px-4">Qulifications</h2>
                    <Markdown className="prose text-black dark:prose-invert dark:text-white pb-2">{jobOfferingData?.qualifications}</Markdown>
                </div>
            )}
            {jobOfferingData?.benefits?.length > 0 && (
                <div className="p-4">
                    <h2 className="text-lg py-1 mb-2 bg-[#FFFB00] text-black rounded-full text-center inline-block px-4">What we offer</h2>
                    <Markdown className="prose text-black dark:prose-invert dark:text-white pb-2">{jobOfferingData?.benefits}</Markdown>
                </div>
            )}
            {jobOfferingData?.link?.length > 0 && (
                <div className="p-4 w-full flex justify-center">
                    <Link to={jobOfferingData.link} target="_blank" onClick={logJobOfferingApplyClick}>
                        <Button variant="contained" color="primary"
                            className="!rounded-full">
                            <Typography style={{ textTransform: 'none' }} className="!text-xl !font-bold">Apply</Typography>
                        </Button></Link>
                </div>
            )}
        </>
    );
};

export default ServiceOfferingDetails;
